import React, { createContext, useEffect, useState, useContext } from 'react';

const AuthContext = createContext({});

function AuthStore(props) {
  const [state, setState] = useState({ isAuthed: false });
  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
    if (token !== null) setState({ isAuthed: true });
    else setState({ isAuthed: false });
  }, [state.isAuthed, token]);

  return <AuthContext.Provider value={{ state, setState }} {...props} />;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error(`useAuth must be used within a AuthProvider`);
  return context;
}

export { AuthStore, useAuth };
