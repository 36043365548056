import React, { lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-multi-carousel/lib/styles.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import logoSmall from './images/logo_small.png';
import ProductCategoriesStore from './store/ProductCategoriesStore';
import BlogCategoriesStore from './store/BlogCategoriesStore';
import ProductsStore from './store/ProductsStore';
import BlogStore from './store/BlogStore';
import { AuthStore } from './store/AuthStore';
import { ToastContainer } from 'react-toastify';

const HomeView = lazy(() => import('./views/HomeView'));
const Contacts = lazy(() => import('./views/Contacts'));
const About = lazy(() => import('./views/About'));
const Process = lazy(() => import('./views/Process'));
const Products = lazy(() => import('./views/Products'));
const ProductInside = lazy(() => import('./views/ProductInside'));
const ProductsTable = lazy(() => import('./views/ProductsTable'));
const ProductImages = lazy(() => import('./views/ProductImages'));
const Inquiry = lazy(() => import('./views/Inquiry'));
const GalleryView = lazy(() => import('./views/GalleryView'));
const Railings = lazy(() => import('./views/Railings'));
const Blog = lazy(() => import('./views/Blog'));
const Doors = lazy(() => import('./views/Doors'));
const BlogTable = lazy(() => import('./views/BlogTable'));
const BlogItem = lazy(() => import('./views/BlogItem'));

function App() {
  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="loading">
            <img src={logoSmall} alt="" className="img-fluid" />
          </div>
        }
      >
        <BrowserRouter>
          <Switch>
            <ProductsStore>
              <BlogStore>
                <ToastContainer />
                <ProductCategoriesStore>
                  <BlogCategoriesStore>
                    <Route exact path="/" component={HomeView} />
                    <Route exact path="/contacts/:name?" component={Contacts} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/process" component={Process} />
                    <Route exact path="/products/:id" component={Products} />
                    <Route exact path="/productInside/:id/:catId" component={ProductInside} />
                    <Route exact path="/railings" component={Railings} />
                    <Route exact path="/doors" component={Doors} />
                    
                    <Route exact path="/blog/:id?" component={Blog} />
                    <Route exact path="/blogitem/:id?" component={BlogItem} />
                    <AuthStore>
                      <Route exact path="/productTable" component={ProductsTable} />
                      <Route exact path="/blogTable" component={BlogTable} />
                      <Route exact path="/productImages/:id" component={ProductImages} />
                      <Route exact path="/inquiry" component={Inquiry} />
                    </AuthStore>
                    <Route exact path="/categories" component={GalleryView} />
                  </BlogCategoriesStore>
                </ProductCategoriesStore>
              </BlogStore>
            </ProductsStore>
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
