import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../api';

export default {
  create: async req => {
    try {
      const res = await axios.post(`${api}/categories/create`, req);
      // toast.success('auth get success');
      return res.data;
    } catch (err) {
      toast.error(err.message);
    }
  },
  delete: async param => {
    try {
      const res = await axios.get(`${api}/categories/delete/${param}`);
      // toast.success('auth get success');
      return res.data;
    } catch (err) {
      toast.error(err.message);
    }
  },
  get: async () => {
    try {
      const res = await axios.get(`${api}/categories/get`);
      // toast.success('auth get success');
      return res.data;
    } catch (err) {
      toast.error(err.message);
    }
  },
  edit: async (param, req) => {
    try {
      const res = await axios.patch(`${api}/categories/edit/${param}`, req);
      // toast.success('auth get success');
      return res.data;
    } catch (err) {
      toast.error(err.message);
    }
  },
};
