import React, { createContext, useState, useEffect } from 'react';
import blogCategories from '../service/blogCategories';

export const BlogCategoriesContext = createContext({});

export default function BlogCategoriesStore({ children }) {
  const [state, setState] = useState({ categories: [] });

  useEffect(() => {
    (async () => {
      try {
        const categories = await blogCategories.get();

        setState({ categories });
      } catch (error) {}
    })();
  }, []);

  return <BlogCategoriesContext.Provider value={[state, setState]}>{children}</BlogCategoriesContext.Provider>;
}
