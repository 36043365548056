import React, { createContext, useState, useEffect } from 'react';
import productCategories from '../service/productCategories';

export const ProductCategoriesContext = createContext({});

export default function ProductCategoriesStore({ children }) {
  const [state, setState] = useState({ categories: [] });

  useEffect(() => {
    (async () => {
      try {
        const categories = await productCategories.get();
        setState({ categories });
      } catch (error) {}
    })();
  }, []);

  return <ProductCategoriesContext.Provider value={[state, setState]}>{children}</ProductCategoriesContext.Provider>;
}
