import React, { createContext, useState, useEffect } from 'react';

export const ProductsContext = createContext({});

export default function ProductsStore({ children }) {
  const [state, setState] = useState({ products: [], isEmpty: true });

  useEffect(() => {
    console.log('products rendered');
  }, []);

  return <ProductsContext.Provider value={[state, setState]}>{children}</ProductsContext.Provider>;
}
